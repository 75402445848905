import React from 'react';
import { Spin } from 'antd';
import { StaticImage } from 'gatsby-plugin-image';
import * as styles from '../../../styles/learner/SkillsAssessmentSubmissionInReview.module.scss';

const SkillsAssessmentSubmissionInReview:React.FC = () => (
  <div className={styles.analysisInReviewContainer}>
    <div className={styles.contentWrapper}>
      <Spin className={styles.loadingSpinner} />
      <h2>Your results are being calculated.</h2>
      <p>
        This will take less than 30 seconds. While you are waiting,
        you can have a look at our Soft-skill eBook.
      </p>
    </div>
    <div className={styles.imageWrapper}>
      <StaticImage
        src="../../../../static/images/learner/soft-skills-stats.png"
        alt="soft skills"
        width={1000}
        height={600}
        placeholder="blurred"
      />
    </div>
  </div>
);

export default SkillsAssessmentSubmissionInReview;
