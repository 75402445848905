import React, { useEffect, useState, useContext } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Link, navigate } from 'gatsby';
import { Progress, Spin } from 'antd';
import { StaticImage } from 'gatsby-plugin-image';
import * as styles from '../../../styles/learner/SkillSummaryView.module.scss';
import {
  Report, ReportResult, Link as SkillReport, SubSkill,
} from '../../../models';
import { fetchSkillsReport, fetchSkillsReportResult } from '../../../services/ReportAnalysisService';
import LearnerContext from '../../../context/Learner/LearnerContext';
import { colourCodes } from '../Services/ReportService';

interface Props extends RouteComponentProps {
  reportId?: string;
  skillId?: string;
}

enum Grade {
  BELOW_AVERAGE = 'Below Average',
  AVERAGE = 'Average',
  ABOVE_AVERAGE = 'Above Average',
  EXCEPTIONAL = 'Exceptional'
}

type SkillSummary = {
  scoreInPercentage: number,
  grade: Grade,
  label: string,
  skillReport: SkillReport | null | undefined,
  subSkills: SubSkill[] | undefined,
  title: string,
  colour: string
}

const getGradeFromPercentage = (score: number) => {
  if (score >= 91) return Grade.EXCEPTIONAL;
  if (score >= 76) return Grade.ABOVE_AVERAGE;
  if (score >= 51) return Grade.AVERAGE;
  return Grade.BELOW_AVERAGE;
};

const SkillSummaryView:React.FC<Props> = ({ reportId, skillId }) => {
  const { profileInfo } = useContext(LearnerContext);

  const [skillSummary, setSkillSummary] = useState<SkillSummary | null>(null);

  function getActiveSkillReport(report:Report) {
    for (let i = 0; i < report.skills.length; i += 1) {
      const skill = report.skills[i];
      if (skill?.id === skillId) {
        const referenceID = report?.sourceReferenceID.split(':').slice(1, 3).join(':');
        return {
          ...skill,
          colour: colourCodes?.[referenceID]?.[i],
        };
      }
    }
    return null;
  }

  const mergeResponse = (report:Report | null, result: ReportResult | null) :
   SkillSummary | null => {
    if (report && report?.skills?.length > 0 && result && result?.skillScores?.length > 0) {
      const activeSkillInReport = getActiveSkillReport(report);
      const activeSkillInResult = result.skillScores.find((
        skill,
      ) => skill?.skillID === skillId);
      const score = activeSkillInResult?.score || 0;
      const maxScore = activeSkillInResult?.maxScore;
      const scoreInPercentage = maxScore ? Math.ceil((score / maxScore) * 100) : 0;
      const grade = getGradeFromPercentage(scoreInPercentage);
      return {
        scoreInPercentage,
        grade,
        label: activeSkillInReport?.label || '',
        skillReport: activeSkillInReport?.skillReport,
        subSkills: activeSkillInReport?.subSkills,
        title: report?.name,
        colour: activeSkillInReport?.colour || '',
      };
    }
    return null;
  };

  const loadDependencies = async () => {
    const report = await fetchSkillsReport();
    const enterpriseId = profileInfo?.enterpriseID || '';
    const result = await fetchSkillsReportResult(reportId, enterpriseId);
    if (!result) {
      navigate('/learner/analysis/');
    }
    const mergedData = mergeResponse(report, result);
    setSkillSummary(mergedData);
  };

  useEffect(() => {
    if (reportId && skillId && profileInfo?.id) {
      loadDependencies();
    }
  }, [reportId, skillId, profileInfo?.id]);

  if (!skillSummary) {
    return (
      <div className={styles.loader}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className={styles.skillAnalysisSummaryContainer}>
      <div className={styles.backButton}>
        <Link id="learner-analysis-skills-summary-back" to={`/learner/analysis/${reportId}/summary/`}>
          <div className={styles.arrowIcon}>
            <span className="library-shaw-icon-new">
              <i className="icon-new-Back-arrow" />
            </span>
          </div>
          <button type="button">Back</button>
        </Link>
      </div>
      <h2 className={styles.mainHeading}>{skillSummary?.title}</h2>
      <div className={styles.summarySection}>
        <div className={styles.headingSection}>
          <h3 className={styles.headingLabel}>{skillSummary?.label}</h3>
          {skillSummary?.skillReport?.href
            ? (
              <button
                id="learner-analysis-skills-summary-download-guide"
                type="button"
                onClick={() => window.open(skillSummary?.skillReport?.href, '_blank')}
              >
                <StaticImage
                  src="../../../../static/images/learner/download-icon.png"
                  alt="download"
                  width={13}
                  height={16}
                  objectFit="contain"
                  placeholder="blurred"
                />
                Download Guide
              </button>
            ) : null}
        </div>
        <p className={styles.subText}>
          Download this guide to learn more about Soft
          Skills and what courses we recommend to help you upskill.
        </p>
        <div className={styles.innerContainer}>
          <div className={styles.skillProgressSection}>
            <Progress
              type="circle"
              strokeColor={skillSummary?.colour || '#ffdba4'}
              strokeWidth={12}
              format={() => `${skillSummary?.scoreInPercentage}%`}
              width={192}
              percent={skillSummary?.scoreInPercentage}
            />
            <div className={styles.line} />
            <div className={styles.skillDetails}>
              <h3>{skillSummary?.label}</h3>
              <p>
                Overall grade -
                {' '}
                {skillSummary?.grade}
              </p>
            </div>
          </div>
          <div className={styles.subSkillsContainer}>
            <h4>
              Skills related to
              {' '}
              {skillSummary?.label}
            </h4>
            <div className={styles.subSkillsDetails}>
              <ul>
                {skillSummary?.subSkills && skillSummary?.subSkills?.length > 0
               && skillSummary?.subSkills.map((subSkill) => (
                 <li key={subSkill?.id}>
                   <p>{subSkill?.label}</p>
                   <p>{subSkill?.description}</p>
                 </li>
               ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkillSummaryView;
