import React, { useEffect } from 'react';
import Chart from 'chart.js/auto';

export interface IRadarChartData {
  labels: string[],
  datasets: {
    label: string,
    data: number[],
    fill: boolean,
    backgroundColor: string,
    borderColor: string,
    pointBackgroundColor?: string,
    pointBorderColor?: string,
    pointHoverBackgroundColor?: string,
    pointHoverBorderColor?: string,
    pointBorderWidth?: number,
    pointRadius?: number,
  }[]
}

type Props = {
  data: IRadarChartData | null,
  customOptions: {
    label: {
      color: string,
      font: {
        size: number,
        weight: string
      }
    },
    ticks: {
      fontSize: number,
    }
    enableTooltip: boolean
  },
};

const RadarChart:React.FC<Props> = ({ data, customOptions }) => {
  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: customOptions?.enableTooltip,
        displayColors: false,
        callbacks: {
          label(context:any) {
            const label = context.dataset.label || '';
            const value = context.formattedValue || 0;
            return `${label} : ${value}%`;
          },
        },
        backgroundColor: '#000',
      },
    },
    elements: {
      line: {
        borderWidth: 2,
      },
    },
    scales: {
      title: {
        display: false,
      },
      r: {
        pointLabels: {
          color: customOptions?.label?.color,
          font: {
            size: customOptions?.label?.font?.size,
            weight: customOptions?.label?.font?.weight,
          },
        },
        angleLines: {
          display: true,
        },
        suggestedMin: 0,
        suggestedMax: 100,
        ticks: {
          font: {
            size: customOptions?.ticks?.fontSize,
          },
          stepSize: 20,
          display: true,
        },
      },
    },
  };

  useEffect(() => {
    let chart:any = null;
    if (data) {
      const canvas:any = document.getElementById('radarCanvas');
      chart = new Chart(canvas, {
        type: 'radar',
        data,
        options,
      });
    }
    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [data]);

  return <canvas width="232" height="226" id="radarCanvas" />;
};

export default RadarChart;
