// extracted by mini-css-extract-plugin
export var analysisSection = "SkillsAssessmentView-module--analysisSection--1y9uc";
export var topAnalyticsSection = "SkillsAssessmentView-module--topAnalyticsSection--1NVWQ";
export var leftInfoSection = "SkillsAssessmentView-module--leftInfoSection--1i6tR";
export var analysisHeader = "SkillsAssessmentView-module--analysisHeader--1JILV";
export var analysisPara = "SkillsAssessmentView-module--analysisPara--3SxVv";
export var submit = "SkillsAssessmentView-module--submit--c81sm";
export var rightGraphSection = "SkillsAssessmentView-module--rightGraphSection--1TLYm";
export var wrapper = "SkillsAssessmentView-module--wrapper--2HBGi";
export var typeFormEmbed = "SkillsAssessmentView-module--typeFormEmbed--17ACt";
export var lineDiv = "SkillsAssessmentView-module--lineDiv--1NKwT";
export var line = "SkillsAssessmentView-module--line--bZ2Iv";
export var bottomAnalyticsContent = "SkillsAssessmentView-module--bottomAnalyticsContent--oXiiW";
export var loader = "SkillsAssessmentView-module--loader--3FEjZ";