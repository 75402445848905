// extracted by mini-css-extract-plugin
export var analysisSection = "SkillsSummaryView-module--analysisSection--2LtZ-";
export var topSection = "SkillsSummaryView-module--topSection--25dtS";
export var filterSection = "SkillsSummaryView-module--filterSection--25DeC";
export var middleSection = "SkillsSummaryView-module--middleSection--1HcBl";
export var graphContainer = "SkillsSummaryView-module--graphContainer--2Fla6";
export var chartWrapper = "SkillsSummaryView-module--chartWrapper--2Vy08";
export var lineDiv = "SkillsSummaryView-module--lineDiv--36f5p";
export var line = "SkillsSummaryView-module--line--35sa1";
export var rightListSection = "SkillsSummaryView-module--rightListSection--nVZnl";
export var loader = "SkillsSummaryView-module--loader--24LeZ";