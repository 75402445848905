import React from 'react';
import { Router } from '@reach/router';
import Layout from '../../../components/Learner/Layout';
import SkillsAssessmentView from '../../../components/Learner/Analysis/SkillsAssessmentView';
import SkillsSummaryView from '../../../components/Learner/Analysis/SkillsSummaryView';
import SkillSummaryView from '../../../components/Learner/Analysis/SkillSummaryView';
import { LearnerProvider } from '../../../context/Learner/LearnerContext';
import Authenticate from '../../../components/Identity/Authenticate';
import useSkillsReportId from '../../../hooks/useSkillsReportId';

const ReportAnalysisPage:React.FC = () => {
  const { reportId } = useSkillsReportId();
  return (
    <LearnerProvider>
      <Layout>
        <Router basepath="/learner/analysis">
          <SkillsAssessmentView path="/:reportId" defaultReportId={reportId} />
          <SkillsSummaryView path="/:reportId/summary" />
          <SkillSummaryView path="/:reportId/skills/:skillId/summary" />
          <SkillsAssessmentView default defaultReportId={reportId} />
        </Router>
      </Layout>
    </LearnerProvider>
  );
};

export default Authenticate(ReportAnalysisPage);
