import React from 'react';
import { Link } from 'gatsby';
import { SkillResult } from './SkillsSummaryView';
import * as styles from '../../../styles/learner/SkillsList.module.scss';

type Props = {
  reportId: string | undefined,
  skillsResult: SkillResult[],
}

const isAscending = (skillsResult:SkillResult[]) => {
  const res = skillsResult.every((
    skill, index,
  ) => index === 0 || skill?.scoreInPercentage >= skillsResult[index - 1]?.scoreInPercentage);
  return res;
};

const SkillsList:React.FC<Props> = ({ reportId, skillsResult }) => {
  const isSortedInAscending = isAscending(skillsResult);

  const addClassName = (skill: SkillResult, i:number) => {
    const totalSkillsCount = skillsResult?.length;
    if (skill?.scoreInPercentage !== 100) {
      if (isSortedInAscending) {
        if (i <= 2) {
          return styles.lowScore;
        }
      }
      if (!isSortedInAscending) {
        if (totalSkillsCount - i <= 3) {
          return styles.lowScore;
        }
      }
    }
    return '';
  };

  return (
    <div className={styles.skillsListContainer}>
      <ul>
        {skillsResult.map((skill, index) => (
          <li
            className={styles.skillBlock}
            key={skill?.skillID}
          >
            <Link to={`/learner/analysis/${reportId}/skills/${skill?.skillID}/summary/`}>
              <div>
                <div className={styles.iconWrapper}>
                  <img
                    src={skill?.iconLink?.href}
                    alt={skill?.label}
                    width={16}
                    height={16}
                    placeholder="blurred"
                    style={{ objectFit: 'contain' }}
                  />
                </div>
                <div className={styles.skillInfo}>
                  <h3>{skill?.label}</h3>
                  <p>
                    Score:
                    {' '}
                    <span className={addClassName(skill, index)}>
                      {skill?.scoreInPercentage}
                      %
                    </span>
                  </p>
                </div>
              </div>
              <span className="library-shaw-icon-new">
                <i className="icon-new-Right-bracket-arrow---1" />
              </span>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SkillsList;
