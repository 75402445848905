/* eslint-disable import/prefer-default-export */
const referenceID = 'blte7b3a75f5e3d792c:en';

type TColourCodes = {
  [key:string]: string[]
}

const colourCodes:TColourCodes = {
  [referenceID]: [
    '#FF9F9F',
    '#B8E8FC',
    '#C8FFD4',
    '#FAF4B7',
    '#DEBACE',
    '#FFB9B9',
    '#C98474',
    '#CEEDC7',
    '#D0B8A8',
    '#A2B38B',
  ],
};

export {
  colourCodes,
};
