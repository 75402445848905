import React, { useEffect, useState, useContext } from 'react';
import { Select, Spin } from 'antd';
import { RouteComponentProps } from '@reach/router';
import { navigate } from 'gatsby';
import * as styles from '../../../styles/learner/SkillsSummaryView.module.scss';
import RadarChart, { IRadarChartData } from '../../Shared/RadarChart';
import { fetchSkillsReport, fetchSkillsReportResult, fetchSkillsReportSubmission } from '../../../services/ReportAnalysisService';
import {
  Report, ReportResult, ReportSubmissionStatus, Skill, SkillScore,
} from '../../../models';
import SkillsList from './SkillsList';
import LearnerContext from '../../../context/Learner/LearnerContext';

interface Props extends RouteComponentProps {
  reportId?: string;
}

enum SortOrder {
  DEFAULT = '',
  ASCENDING = 'ascending',
  DESCENDING = 'descending'
}

export type SkillResult = SkillScore & Skill & {
  scoreInPercentage: number,
  included: {
    title: string,
  }
};

const chartOptions = {
  label: {
    color: '#222222',
    font: {
      size: 14,
      weight: 'normal',
    },
  },
  ticks: {
    fontSize: 13,
  },
  enableTooltip: true,
};

const selectOptions = [
  {
    value: SortOrder.ASCENDING,
    label: 'Low - High',
  },
  {
    value: SortOrder.DESCENDING,
    label: 'High - Low',
  },
];

const SkillsSummaryView: React.FC<Props> = ({ reportId }) => {
  const { profileInfo } = useContext(LearnerContext);

  const [skillsResult, setSkillsResult] = useState<SkillResult[]>([]);
  const [chartData, setChartData] = useState<IRadarChartData>();

  const prepareChartData = (skills:SkillResult[]) => {
    const labels = skills.map((skill) => skill.label);
    const scoreList = skills.map((skill) => skill.scoreInPercentage);
    const data:IRadarChartData = {
      labels,
      datasets: [{
        label: 'Score',
        data: scoreList,
        fill: true,
        backgroundColor: 'rgba(156, 106, 249, 0.1)',
        borderColor: '#9570f1',
        pointBackgroundColor: 'rgb(156, 106, 249)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgb(156, 106, 249)',
      }],
    };
    setChartData(data);
  };

  const mergeResponse = (report:Report | null, result: ReportResult | null) : SkillResult[] => {
    if (report && report?.skills?.length > 0 && result && result?.skillScores?.length > 0) {
      const reportMap = new Map();
      const mergedData:SkillResult[] = [];
      report.skills.forEach((skill) => {
        const skillId = skill?.id || '';
        reportMap.set(skillId, skill);
      });
      result.skillScores.forEach((skillResult) => {
        const skillId = skillResult?.skillID;
        if (reportMap.has(skillId)) {
          const reportData:Skill = reportMap.get(skillId);
          const score = skillResult?.score || 0;
          const maxScore = skillResult?.maxScore;
          const scoreInPercentage = maxScore ? Math.ceil((score / maxScore) * 100) : 0;
          mergedData.push({
            ...skillResult,
            ...reportData,
            scoreInPercentage,
            included: {
              title: report?.name,
            },
          });
        }
      });
      return mergedData;
    }
    return [];
  };

  const sortBasedOnScore = (value: string) => {
    const copyOfSkillsResult = [...skillsResult];
    let sortedResult;
    if (value === SortOrder.ASCENDING) {
      sortedResult = copyOfSkillsResult.sort((
        a, b,
      ) => a.scoreInPercentage - b.scoreInPercentage);
    } else {
      sortedResult = copyOfSkillsResult.sort((
        a, b,
      ) => b.scoreInPercentage - a.scoreInPercentage);
    }
    setSkillsResult(sortedResult);
  };

  const loadDependencies = async () => {
    const enterpriseId = profileInfo?.enterpriseID || '';
    const reportSubmission = await fetchSkillsReportSubmission(reportId, enterpriseId);
    const result = await fetchSkillsReportResult(reportId, enterpriseId);
    if (reportSubmission
      && !result?.archived
       && reportSubmission?.status !== ReportSubmissionStatus.STARTED) {
      const report = await fetchSkillsReport();
      const mergedData = mergeResponse(report, result);
      prepareChartData(mergedData);
      // By default sort skills from low score to high score
      mergedData.sort((a, b) => a.scoreInPercentage - b.scoreInPercentage);
      setSkillsResult(mergedData);
    } else {
      navigate(`/learner/analysis/${reportId}/`);
    }
  };

  // NOTE: Not included for current release
  // const getRecommendedSkills = () => {
  //   const sorted = [...skillsResult].sort((a, b) => a.scoreInPercentage - b.scoreInPercentage);
  //   const threeSkillsWithLeastScores = sorted.slice(0, 3);
  //   return threeSkillsWithLeastScores;
  // };

  // const recommendedSkills = getRecommendedSkills();

  useEffect(() => {
    if (reportId && profileInfo?.id) {
      loadDependencies();
    }
  }, [reportId, profileInfo?.id]);

  if (!skillsResult?.length) {
    return (
      <div className={styles.loader}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <>
      <div className={styles.analysisSection}>
        <div className={styles.topSection}>
          <h2>{skillsResult?.[0]?.included?.title}</h2>
          <div className={styles.filterSection}>
            <p>Sorted by score</p>
            <Select
              defaultValue={SortOrder.ASCENDING}
              style={{ width: 138 }}
              onChange={sortBasedOnScore}
              options={selectOptions}
            />
          </div>
        </div>
        <div className={styles.middleSection}>
          <div className={styles.graphContainer}>
            {chartData
              ? (
                <div className={styles.chartWrapper}>
                  <RadarChart data={chartData} customOptions={chartOptions} />
                </div>
              )
              : null}
            <p>
              The graph presents your score per soft skill calculated based
              on your chosen answers. A score above 75% is considered above average.
            </p>
          </div>
          <div className={styles.lineDiv}>
            <div className={styles.line} />
          </div>
          <SkillsList
            reportId={reportId}
            skillsResult={skillsResult}
          />
        </div>
      </div>
    </>
  );
};

export default SkillsSummaryView;
