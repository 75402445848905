// extracted by mini-css-extract-plugin
export var skillAnalysisSummaryContainer = "SkillSummaryView-module--skillAnalysisSummaryContainer--wb60x";
export var backButton = "SkillSummaryView-module--backButton--1cp-Y";
export var arrowIcon = "SkillSummaryView-module--arrowIcon--2bdAk";
export var mainHeading = "SkillSummaryView-module--mainHeading--A7jhg";
export var summarySection = "SkillSummaryView-module--summarySection--3brfi";
export var headingSection = "SkillSummaryView-module--headingSection--k6Qtw";
export var headingLabel = "SkillSummaryView-module--headingLabel--2e3Zz";
export var subText = "SkillSummaryView-module--subText--aXm2-";
export var innerContainer = "SkillSummaryView-module--innerContainer--DjnKJ";
export var skillProgressSection = "SkillSummaryView-module--skillProgressSection--2UrpP";
export var line = "SkillSummaryView-module--line--1MGMF";
export var skillDetails = "SkillSummaryView-module--skillDetails--1ZEaq";
export var subSkillsContainer = "SkillSummaryView-module--subSkillsContainer--36L30";
export var subSkillsDetails = "SkillSummaryView-module--subSkillsDetails--3DU-j";
export var bottomSection = "SkillSummaryView-module--bottomSection--yGg-J";
export var titleSection = "SkillSummaryView-module--titleSection--3aw6h";
export var courseSection = "SkillSummaryView-module--courseSection--28PNs";
export var courseBlock = "SkillSummaryView-module--courseBlock--1au-J";
export var leftBlock = "SkillSummaryView-module--leftBlock--2CL-V";
export var rightBlock = "SkillSummaryView-module--rightBlock--VqM2L";
export var vl = "SkillSummaryView-module--vl--110Z2";
export var loader = "SkillSummaryView-module--loader--3NIoj";