import React, { useEffect, useState, useContext } from 'react';
import { RouteComponentProps, useLocation } from '@reach/router';
import { StaticImage } from 'gatsby-plugin-image';
import { navigate } from 'gatsby';
import { Widget } from '@typeform/embed-react';
import { API, DataStore, Hub } from 'aws-amplify';
import htmr from 'htmr';
import { Spin } from 'antd';
import * as styles from '../../../styles/learner/SkillsAssessmentView.module.scss';
import RadarChart, { IRadarChartData } from '../../Shared/RadarChart';
import {
  Report, ReportSubmission, ReportSubmissionStatus, Skill,
} from '../../../models';
import LearnerContext from '../../../context/Learner/LearnerContext';
import SkillsAssessmentSubmissionInReview from './SkillsAssessmentSubmissionInReview';
import {
  fetchSkillsReport,
  fetchSkillsReportResult,
  fetchSkillsReportSubmission,
} from '../../../services/ReportAnalysisService';
import { createReportSubmission, updateReportSubmission } from '../../../graphql/mutations';

interface Props extends RouteComponentProps {
  reportId?: string;
  defaultReportId?: string;
}

const CHART_DATA_VALUES = [74, 90, 23, 88, 73, 65, 95, 30, 65, 88, 49, 70, 60, 88, 32, 60];

const chartOptions = {
  label: {
    color: '#52556e',
    font: {
      size: 11,
      weight: 'normal',
    },
  },
  ticks: {
    fontSize: 9,
  },
  enableTooltip: false,
};

type TReportSubmissionWithVersion = ReportSubmission & {
  _version: number,
}

let reportSubmissionObserver: any;

const SkillsAssessmentView: React.FC<Props> = ({ reportId, defaultReportId }) => {
  const { profileInfo } = useContext(LearnerContext);

  const [reportState, setReportState] = useState<ReportSubmissionStatus>();
  const [report, setReport] = useState<Report | null>(null);
  const [chartData, setChartData] = useState<IRadarChartData | null>(null);
  const [formId, setFormId] = useState('');
  const [loader, setLoader] = useState(true);

  const location: any = useLocation();

  const prepareChartData = (skills: Skill[]) => {
    const labels = skills.map((skill) => skill.label);
    const dataValues = skills.map((_, i) => CHART_DATA_VALUES[i] || 0);
    const data:IRadarChartData = {
      labels,
      datasets: [{
        label: 'My First Dataset',
        data: dataValues,
        fill: true,
        backgroundColor: 'rgba(156, 106, 249, 0.1)',
        borderColor: '#9570f1',
        pointBorderWidth: 0,
        pointRadius: 0,
      }],
    };
    setChartData(data);
  };

  const buildFormIdWithParams = (reportResponse: Report) => {
    const formID = reportResponse?.assessment?.formId;
    const params: any = {
      student_id: profileInfo?.id,
      user_name: profileInfo?.name,
      locale: 'en',
      sub_brand: window.location.hostname.indexOf('localhost') > -1
        ? 'dev.d3di9m8c2ff7yj.amplifyapp.com' : window.location.hostname, // TODO: fix
      assessment_type: 'skill_gap_analysis',
      report_id: reportResponse?.id,
      enterprise_id: profileInfo?.enterpriseID,
    };

    const formIdWithParams = Object.keys(params).reduce((acc, cur, index) => {
      if (!index) return `${acc}#${cur}=${params[cur]}`;
      return `${acc}&${cur}=${params[cur]}`;
    }, formID);

    if (formIdWithParams) {
      setFormId(formIdWithParams);
    }
  };

  const getSkillsReport = async () => {
    const response = await fetchSkillsReport();
    if (response) {
      prepareChartData(response?.skills || []);
      buildFormIdWithParams(response);
      setReport(response);
    } else {
      navigate('/learner/');
    }
    setLoader(false);
  };

  const checkReportResultAndSubmissionStatus = async () => {
    const enterpriseId = profileInfo?.enterpriseID || '';
    const reportSubmission = await fetchSkillsReportSubmission(reportId, enterpriseId);
    if (reportSubmission) {
      if (reportSubmission?.status === ReportSubmissionStatus.COMPLETED) {
        const reportResult = await fetchSkillsReportResult(reportId, enterpriseId);
        if (reportResult) {
          navigate(`/learner/analysis/${reportId}/summary/`);
          return true;
        }
      }
      if (reportSubmission?.status === ReportSubmissionStatus.IN_REVIEW) {
        setReportState(ReportSubmissionStatus.IN_REVIEW);
      }
    }
    return false;
  };

  const updateReportSubmissionStatus = async (version:number, status: ReportSubmissionStatus) => {
    try {
      const userID = profileInfo?.id;
      const id = `${reportId}:${userID}`;
      await API.graphql({
        query: updateReportSubmission,
        variables: {
          input: {
            id,
            reportID: reportId,
            userID,
            userSub: profileInfo?.sub,
            status,
            enterpriseID: profileInfo?.enterpriseID,
            _version: version,
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const submitAssessment = async () => {
    setReportState(ReportSubmissionStatus.IN_REVIEW);
    const enterpriseId = profileInfo?.enterpriseID || '';
    const submissionReport = await fetchSkillsReportSubmission(
      reportId, enterpriseId,
    ) as TReportSubmissionWithVersion;
    if (submissionReport?.status === ReportSubmissionStatus.STARTED) {
      // eslint-disable-next-line no-underscore-dangle
      const version = submissionReport?._version;
      updateReportSubmissionStatus(version, ReportSubmissionStatus.IN_REVIEW);
    }
  };

  const startAssessment = async () => {
    setReportState(ReportSubmissionStatus.STARTED);
    try {
      const userID = profileInfo?.id;
      const id = `${reportId}:${userID}`;
      await API.graphql({
        query: createReportSubmission,
        variables: {
          input: {
            id,
            reportID: reportId,
            userID,
            userSub: profileInfo?.sub,
            status: ReportSubmissionStatus.STARTED,
            enterpriseID: profileInfo?.enterpriseID,
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const loadDependencies = async () => {
    if (!reportId) {
      navigate(`/learner/analysis/${defaultReportId}/`);
      return;
    }
    if (reportId !== defaultReportId) {
      navigate(`/learner/analysis/${defaultReportId}/`);
      return;
    }

    const isReportResultPresent = await checkReportResultAndSubmissionStatus();
    if (!isReportResultPresent) {
      getSkillsReport();

      reportSubmissionObserver = DataStore.observe(ReportSubmission).subscribe((msg) => {
        if (msg.element.status === ReportSubmissionStatus.COMPLETED) {
          navigate(`/learner/analysis/${reportId}/summary/`);
        }
      });
    }
  };

  const syncDataStore = async () => {
    await DataStore.stop();
    await DataStore.start();
    const datastoreListener = Hub.listen('datastore', async (hubData) => {
      const { event, data } = hubData.payload;
      if (event === 'ready') {
        console.log('DS listener', data);

        loadDependencies();
      }
    });

    return datastoreListener;
  };

  useEffect(() => {
    if (profileInfo?.id) {
      let cleanupFunction: any;
      if (location?.state?.internalPageNavigation) {
        loadDependencies();

        cleanupFunction = () => {
          if (reportSubmissionObserver) {
            reportSubmissionObserver.unsubscribe();
            reportSubmissionObserver = null;
          }
        };
      } else {
        let listener: any;
        (async () => {
          const datastoreListener = await syncDataStore();
          listener = datastoreListener;
        })();

        cleanupFunction = () => {
          if (reportSubmissionObserver) {
            reportSubmissionObserver.unsubscribe();
            reportSubmissionObserver = null;
          }
          listener();
        };
      }

      return cleanupFunction;
    }

    return () => {};
  }, [reportId, profileInfo?.id, defaultReportId]);

  if (loader) {
    return (
      <div className={styles.loader}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <>
      {reportState === ReportSubmissionStatus.IN_REVIEW && <SkillsAssessmentSubmissionInReview />}
      <div className={styles.analysisSection}>
        {reportState === ReportSubmissionStatus.STARTED
              && (
              <Widget
                id={formId}
                className={styles.typeFormEmbed}
                onSubmit={submitAssessment}
              />
              )}
        {!reportState
                  && (
                  <div>
                    <div className={styles.topAnalyticsSection}>
                      <div className={styles.leftInfoSection}>
                        <StaticImage
                          src="../../../../static/images/learner/analyse-skill.png"
                          alt="close"
                          width={48}
                          height={46}
                          objectFit="contain"
                          placeholder="blurred"
                        />
                        <h2 className={styles.analysisHeader}>{report?.heading}</h2>
                        <p className={styles.analysisPara}>
                          {report?.subHeading}
                        </p>
                        <button
                          id="learner-analysis-start-now"
                          type="button"
                          className={styles.submit}
                          onClick={startAssessment}
                        >
                          {report?.startCTA}
                        </button>
                      </div>
                      <div className={styles.rightGraphSection}>
                        {chartData
                          ? (
                            <div className={styles.topSection}>
                              <div className={styles.wrapper}>
                                <RadarChart data={chartData} customOptions={chartOptions} />
                              </div>
                            </div>
                          ) : null}
                      </div>
                    </div>
                  </div>
                  )}
        {(!reportState || reportState === ReportSubmissionStatus.STARTED)
          && (
          <>
            <div className={styles.lineDiv}>
              <hr className={styles.line} />
            </div>
            <div className={styles.bottomAnalyticsContent}>
              {report?.explainer && htmr(report.explainer)}
            </div>
          </>
          )}
      </div>
    </>
  );
};

export default SkillsAssessmentView;
